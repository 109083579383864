import {
  MaterialLessonCollectionDetails,
  AMaterialLessonTemplate,
  AMaterialLessonItemTemplate,
  AMaterialLessonBlockTemplate,
  AMaterialLessonGroupTemplate,
} from "../models";
import * as InteractiveContents from "./PB-BlockTemplates/index";

const TopicIntro = InteractiveContents.TopicIntro;
const SectionIntro = InteractiveContents.SectionIntro;
const ImagesAndTexts = InteractiveContents.ImagesAndTexts;
const ImagesBlocks = InteractiveContents.Images;
const TextBlocks = InteractiveContents.Texts;
const VideosBlock = InteractiveContents.VideoContent;
const SectionOneContents = InteractiveContents.SectionOneContents;
const HorizontalVerticalBlocks = InteractiveContents.HorizontalVerticalBlocks;

const videos = [
  "https://www.youtube.com/embed/jL88IAxoYOk?si=40ShiLZgZFklgLtD",
  "https://www.youtube.com/embed/oY3XNJUrryI?si=sVTvhbGgOf_OcVlS",
];
const images = ["/images/figma_image_rect.png"];
const text = {
  title: {
    sample: "this is a text title",
    size: "61px",
    color: "var(--primary)",
  },
  subtitle: {
    sample: "this is a text subtitle",
    size: "14px",
    color: "var(--accent)",
  },
  desc: {
    sample: "this is a text description",
    size: "16px",
    color: "black",
  },
};
const items: AMaterialLessonItemTemplate[] = [
  {
    name: "full image",
    template: {
      type: "image",
      value: images[0],
      style: {
        width: "100%",
        height: "100%",
      },
    },
  },

  {
    name: "half image vertical",
    template: {
      type: "image",
      value: images[0],
      style: {
        width: "100%",
        height: "50%",
      },
    },
  },
  {
    name: "half image horizontal",
    template: {
      type: "image",
      value: images[0],
      style: {
        width: "50%",
        height: "100%",
      },
    },
  },
  {
    name: "video",
    template: {
      type: "video",
      value: videos[0],
      style: {
        width: "100%",
        height: "100%",
      },
    },
  },
  {
    name: "video half vertical",
    template: {
      type: "video",
      value: videos[1],
      style: {
        width: "100%",
        height: "50%",
      },
    },
  },
  {
    name: "video half horizontal",
    template: {
      type: "video",
      value: videos[1],
      style: {
        width: "50%",
        height: "100%",
      },
    },
  },
  {
    name: "text",
    template: {
      type: "text",
      value: "this is text",
      style: {
        width: "100%",
        height: "100%",
      },
    },
  },
  {
    name: "text large",
    template: {
      type: "text",
      value: "this is text",
      style: {
        fontSize: "40px",
        width: "100%",
        height: "100%",
      },
    },
  },
  {
    name: "text large (bold)",
    template: {
      type: "text",
      value: "this is text",
      style: {
        fontWeight: "700",
        fontSize: "40px",
        width: "100%",
        height: "100%",
      },
    },
  },
  {
    name: "text xtra large",
    template: {
      type: "text",
      value: "this is bold text",
      style: {
        fontSize: "60px",
        width: "100%",
        height: "100%",
      },
    },
  },
  {
    name: "text xtra large (bold)",
    template: {
      type: "text",
      value: "this is bold text",
      style: {
        fontWeight: "700",
        fontSize: "60px",
        width: "100%",
        height: "100%",
      },
    },
  },
];

const groups: AMaterialLessonGroupTemplate[] = [
  {
    name: "full group horizontal",
    template: {
      name: "full group horizontal",
      items: [],
      style: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
        border: "2px solid magenta",
      },
    },
  },
  {
    name: "full group vertical",
    template: {
      name: "full group vertical",
      items: [],
      style: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        border: "2px solid magenta",
      },
    },
  },
  {
    name: "half group horizontal",
    template: {
      name: "half group horizontal",
      items: [],
      style: {
        width: "50%",
        height: "100%",
        border: "2px solid magenta",
      },
    },
  },
  {
    name: "half group vertical",
    template: {
      name: "half group vertical",
      items: [],
      style: {
        width: "100%",
        height: "50%",
        border: "2px solid magenta",
      },
    },
  },
];
const blocks: AMaterialLessonBlockTemplate[] = [
  ...HorizontalVerticalBlocks,
  ...TopicIntro,
  ...SectionIntro,
  ...ImagesAndTexts,
  ...ImagesBlocks,
  ...TextBlocks,
  ...VideosBlock,
  ...SectionOneContents,
];

const pages: AMaterialLessonTemplate[] = [
  {
    name: "the only page",
    template: {
      blocks: [],
      style: {
        // border: "2px solid red",
        height: "100%",
      },
    },
  },
];

export const materialContentLessonTemplateDetails: MaterialLessonCollectionDetails = {
  message: "success",
  error: null,
  data: {
    items,
    groups,
    blocks,
    pages,
  },
};
